import React, { createContext, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState({
        token: localStorage.getItem('token'),
        isAuthenticated: !!localStorage.getItem('token'),
        user: null
    });

    const setAuthInfo = ({ token, user }) => {
        localStorage.setItem('token', token);
        setAuthState({
            token,
            user,
            isAuthenticated: true
        });
    };

    const logout = () => {
        localStorage.removeItem('token');
        setAuthState({
            token: null,
            isAuthenticated: false,
            user: null
        });
    };

    return (
        <AuthContext.Provider value={{ authState, setAuthInfo, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
