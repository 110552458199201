import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
import '../App.css'
import logo from '../images/rockwit_logo.jpg';
import stamp from '../images/pshj_stamp_final.png';
import aireading from '../images/aireading.png';

const Home = () => {
  const { authState, logout } = useContext(AuthContext);
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios.get('/api/items')
      .then(response => {
        setItems(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" />
        
        <h1>Welcome, {authState.user?.username}</h1>
        <button onClick={logout}>Logout</button>
        <p>
          Wenbo AI - Enabling Everyone to Learn Faster
        </p>
        <img src={aireading} width="200" alt="aireading" />
        <img src={stamp} width="20" alt="stamp" />
      </header>
      <content className="App-content">
        <p>
          Be patient, this site is under construction.
          For any question, please contact info@rockwit.com
        </p>
        <h3>Items</h3>
        <ul>
          {items.map(item => (
            <li key={item._id}>
              <h3>{item.name}</h3>
              <p>{item.description}</p>
            </li>
          ))}
        </ul>            
        <h6>All rights reserved. Rockwit Information Technology Co., Ltd.</h6>

      </content>

    </div>
  );
};

export default Home;
